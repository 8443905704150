import React, { Component } from 'react';
import { QuestionCard } from "./style";
import PropTypes from 'prop-types';

export default class QuestionTile extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    }
  }

  render() {
    const { open } = this.state;
    const { item, index } = this.props;
    return (
      <QuestionCard selected={this.state.open !== false ? 1 : 0} bgColor={"#F5FBFF"} font={this.state.open !== false ? "normal" : "500"}>
        <div onClick={() => this.setState({ open: !open })}>
          <p className={this.state.open !== false ? "question-open" : "question-close"}>
            {item.question}
          </p>
          <span className="arrow"><i className={`fa fa-angle-right ${open && 'fa fa-angle-down'}`} /></span>
        </div>
        {
          open ?
            <div className="accordion">
              <p>{item.answer} {index === 6 && <span> <a href="mailto:sales@woliba.io"> sales@woliba.io</a></span>}</p>
            </div>
            :
            null
        }
      </QuestionCard>
    )
  }
}

QuestionTile.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};