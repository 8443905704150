import React, { Component } from 'react'
import AddOnSectionNew from './AddonSectionNew';
import {WrapperContainer} from './style'
import PropTypes from 'prop-types';

class BilledAnnually extends Component {
  render() {
    const { history } = this.props;
    return (
      <WrapperContainer>
        <AddOnSectionNew history={history}/>
      </WrapperContainer>
    )
  }
}

BilledAnnually.propTypes = {
  history: PropTypes.object
}

export default BilledAnnually;