import React, { Component } from 'react'
import { TeamTabContainerV2 } from "./styles";
import PropTypes from 'prop-types';
import BilledMonthly from './BilledMonthly';
import BilledAnnually from './BilledAnnually';
import { ImageUrl } from '../../utils/constants';

class BilledPackage extends Component {
  constructor() {
    super();
    this.state = {
      active: true
    }
  }

  toggleTabs = (status) => this.setState({ active: status });

  renderBills = () => (
    <TeamTabContainerV2 tabActive setTabWidth active={this.state.active}>
      <div>
        <div>
          <div onClick={() => this.toggleTabs(true)}>Billed Monthly</div>
          <div onClick={() => this.toggleTabs(false)}>Billed Annually</div>
        </div>
      </div>
      <div className='show-arrow'>
        <img className ='arow-img' src={ImageUrl + '/images/arrowimg.svg'} />
        <div className='arrow-text'>Get 2 Months Free</div>
        <img className='arrow-emoji' src={ImageUrl +'/images/smiling-heart.svg'} />
      </div>
    </TeamTabContainerV2>
  );
  render() {
    const { active } = this.state;
    const { history } = this.props;
    return (
      <div>
        {this.renderBills()}         
        {
          active ?
            <BilledMonthly history={history}/>
            :
            <BilledAnnually history={history}/>
        }
      </div>
    )
  }
}

BilledPackage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default BilledPackage;