import React, { Component } from 'react'
import AddOnSection from './AddonSection'
import {WrapperContainer} from './style'
import PropTypes from 'prop-types';

class BilledMonthly extends Component {
  render() {
    const { history } = this.props;
    return (
      <WrapperContainer>
        <AddOnSection history={history}/>
      </WrapperContainer>
    )
  }
}

BilledMonthly.propTypes = {
  history: PropTypes.object
}

export default BilledMonthly;